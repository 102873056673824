<template lang="html">

  <section class="site-map">
    <bannerHead dataTitle="Mapa Web"></bannerHead>
    <breacrumbs dataTitle="Mapa Web"></breacrumbs>
    <div class="container list-map">
      <ul>
        <li>
          <router-link :to="{ name: 'home' }">Inicio</router-link>
        </li>
        <li v-if="store.departments">
          <p>Mis gestiones</p>
          <ul>
            <template v-for="(departament, key) in store.departments" :key="key">
              <li>
                <router-link :to="{name:'gestion-page', 
                                        params: {
                                          slug: departament.slug,
                                          name: departament.name}
                                        }">
                  {{departament.name}}
                  <span v-if="departament.new" class="dest">N</span>
                </router-link>
              </li>
            </template>
          </ul>
        </li>
        <li>
          <router-link :to="{ name: 'team' }">Equipo</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'entriesPage' }">Comunidad UFV</router-link>
        </li>
        <li>
          <p>Responsabilidad Social</p>
          <ul>
            <li>
              <router-link :to="{name :'projects', params: {slug: 'integra'}}">Proyectos</router-link>
            </li>
            <li>
              <router-link :to="{name :'colaborations', params: {slug: 'voluntariado-corporativo'}}">Colabora
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link :to="{ name: 'contact' }">Contacto</router-link>
        </li>
      </ul>
    </div>
  </section>

</template>

<script lang="js">
  import { contentStore } from '@/stores/contents'
  import bannerHead from '@/components/banner-head.vue'
  import breacrumbs from '@/components/breacrumbs.vue'
  export default {
    name: 'site-map',
    props: [],
    components: {
      bannerHead,
      breacrumbs
    },
    setup() {
      const store = contentStore();

      return {
        store
      }
    },

  }


</script>

<style scoped lang="scss">
  .site-map {}
</style>